/* Auth.module.css */

.container {
    background-image: repeating-linear-gradient(
        0deg,
        rgb(246, 247, 248) 0px,
        rgb(246, 247, 248) 2.5px,
        rgb(238, 237, 241) 2.5px,
        rgb(244, 245, 247) 3.2px,
        rgb(238, 237, 241) 3.8px,
        rgb(238, 237, 241) 3.9px
    );
    padding: 0;
    margin: 0;
    height: 100vh;
    position: relative;
    overflow-y: hidden;
}

.authContainer {
    min-height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
    gap: 1.5rem;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 4.5rem;
    animation: fadeIn 0.5s ease-out;
}

/* Special styling for auth view to keep it centered */
.authContainer.isAuth {
    justify-content: center;
}

.authContainer h1 {
    font-family: 'Geist Bold';
    color: #1f2937;
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    animation: floatTitle 6s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.container {
    min-height: 100vh;
    background-image: repeating-linear-gradient(
        0deg,
        rgb(246, 247, 248) 0px,
        rgb(246, 247, 248) 2.5px,
        rgb(238, 237, 241) 2.5px,
        rgb(244, 245, 247) 3.2px,
        rgb(238, 237, 241) 3.8px,
        rgb(238, 237, 241) 3.9px
    );
    overflow-x: hidden;
    font-family: 'Geist Regular';
    position: relative;
}

/* Enhanced animations */
.headerIcon {
    color: #3b82f6;
    animation: bounce 2s infinite;
    text-align: center;
    transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.headerIcon:hover {
    transform: scale(1.1);
}

.headerIcon svg {
    filter: drop-shadow(0 2px 4px rgba(59, 130, 246, 0.2));
}

/* Fix input width and alignment */
.inputWrapper {
    position: relative;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
}

.inputGroup {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.inputIcon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    z-index: 1;
    height: 20px;
    color: #6b7280;
    transition: color 0.3s ease;
}

.input {
    width: 100%;
    height: 48px;
    padding: 0 1rem 0 3rem;
    border-radius: 0.8rem;
    border: 1.5px solid #e5e7eb;
    background: linear-gradient(
        to bottom,
        rgba(249, 250, 251, 0.95),
        rgba(243, 244, 246, 0.8)
    );
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    font-family: 'GeistMono Regular';
    font-size: 1rem;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.input:focus {
    outline: none;
    border-color: #93c5fd;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
    transform: translateY(-1px);
}
.input:focus + .inputIcon {
    color: #3b82f6 !important;
    border-color: red;
    color: red;
}

.button {
    width: 100%;
    max-width: 320px;
    height: 48px;
    border: none;
    border-radius: 0.8rem;
    background: linear-gradient(
        to bottom,
        rgba(251, 252, 253, 0.95),
        rgba(243, 244, 246, 0.9)
    );
    border: 1.5px solid rgb(215, 227, 231);
    color: #1f2937;
    font-family: 'Geist SemiBold';
    font-size: 1rem;
    transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.buttonIcon {
    width: 18px;
    height: 18px;
    transition: transform 0.3s ease;
}

.button:not(:disabled):hover .buttonIcon {
    transform: translateX(2px);
}

.button:not(:disabled)::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
        120deg,
        transparent 0%,
        rgba(59, 130, 246, 0.1) 50%,
        transparent 100%
    );
    opacity: 0;
    transition: opacity 0.3s ease;
}

.button:not(:disabled):hover {
    transform: translateY(-2px);
    border-color: #93c5fd;
    box-shadow: 0 4px 12px rgba(59, 130, 246, 0.15);
}

.button:not(:disabled):hover::before {
    opacity: 1;
    animation: shimmer 2s infinite;
}

.button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    background: linear-gradient(to bottom, #f9fafb, #f3f4f6);
}

/* Key management styles */
.meHeader {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    color: #3b82f6;
}

.keysList {
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin-bottom: 1.5rem;
}

.keyCard {
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.9),
        rgba(249, 250, 251, 0.9)
    );
    border: 1.5px solid #e5e7eb;
    border-radius: 0.8rem;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;
    animation: slideIn 0.5s ease-out;
}

.keyCard:hover {
    transform: translateY(-2px);
    border-color: #93c5fd;
    box-shadow: 0 4px 12px rgba(59, 130, 246, 0.1);
}

.keyInfo {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.keyName {
    font-family: 'Geist Medium';
    color: #1f2937;
    font-size: 0.9rem;
}

.keyId {
    font-family: 'GeistMono Regular';
    color: #6b7280;
    font-size: 0.75rem;
}

.keyEditTrigger {
    background: none;
    border: none;
    color: #6b7280;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 0.5rem;
    transition: all 0.2s ease;
}

.keyEditTrigger:hover {
    color: #3b82f6;
    background: rgba(59, 130, 246, 0.1);
}

.keyEditForm {
    display: flex;
    gap: 0.5rem;
    width: 100%;
}

.keyEditInput {
    flex: 1;
    height: 36px;
    padding: 0 0.75rem;
    border-radius: 0.5rem;
    border: 1.5px solid #e5e7eb;
    font-family: 'GeistMono Regular';
    font-size: 0.9rem;
    background: white;
}

.keyEditInput:focus {
    outline: none;
    border-color: #93c5fd;
}

.keyEditButton {
    background: #3b82f6;
    border: none;
    color: white;
    width: 36px;
    height: 36px;
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
}

.keyEditButton:hover {
    background: #2563eb;
}

.keyEditButton.cancelButton {
    background: #ef4444;
}

.keyEditButton.cancelButton:hover {
    background: #dc2626;
}

.buttonGroup {
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.button.addButton {
    background: linear-gradient(to bottom, #eff6ff, #dbeafe);
    border-color: #93c5fd;
    color: #2563eb;
}

.button.refreshButton {
    background: linear-gradient(to bottom, #f9fafb, #f3f4f6);
}

.button.logoutButton {
    background: linear-gradient(to bottom, #fee2e2, #fecaca);
    border-color: #fca5a5;
    color: #dc2626;
}

/* Auto-scrolling log container */
.logContainer {
    position: fixed;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 280px;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.95),
        rgba(249, 250, 251, 0.9)
    );
    border: 1.5px solid rgb(215, 227, 231);
    border-radius: 1rem;
    padding: 0.5rem 0.8rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.logContainer h2 {
    font-family: 'Geist SemiBold';
    color: #1f2937;
    font-size: 14px;
    margin-left: 4px;
    margin-bottom: 8px;
    text-align: left;
}

.logContainer svg {
    width: 12px;
    height: 12px;
    margin-right: 2px;
    color: #0968ff;
    animation: bouneAndSpin 4s infinite;
}

.logContainer ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: 'GeistMono Regular';
    font-size: 0.875rem;
    color: #6b7280;
    max-height: 150px;
    overflow-y: auto;
    scroll-behavior: smooth; /* Smooth scrolling */
    scrollbar-width: thin;
    scrollbar-color: #93c5fd transparent;
}

.logContainer ul::-webkit-scrollbar {
    width: 6px;
}

.logContainer ul::-webkit-scrollbar-track {
    background: transparent;
}

.logContainer ul::-webkit-scrollbar-thumb {
    background-color: #93c5fd;
    border-radius: 3px;
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes bouneAndSpin {
    0%,
    100% {
        transform: translateY(-4px) rotate(0deg);
    }
    50% {
        transform: translateY(-6px) rotate(180deg);
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(-10px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

@keyframes bounce {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

@keyframes bounce2 {
    0%,
    100% {
        transform: translateY(-2);
    }
    50% {
        transform: translateY(-6px);
    }
}

@keyframes floatTitle {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
}

/* Add sparkle effects similar to your device cards */
.sparkleContainer {
    position: absolute;
    inset: -20px;
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.button:not(:disabled):hover .sparkleContainer {
    opacity: 1;
}

.sparkle {
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: linear-gradient(135deg, #60a5fa, #3b82f6);
    animation: sparkle 2s infinite;
}

.sparkle:nth-child(1) {
    top: 20%;
    left: 10%;
    animation-delay: 0s;
}
.sparkle:nth-child(2) {
    top: 60%;
    right: 10%;
    animation-delay: 0.5s;
}
.sparkle:nth-child(3) {
    bottom: 20%;
    left: 15%;
    animation-delay: 1s;
}
.sparkle:nth-child(4) {
    top: 30%;
    right: 15%;
    animation-delay: 1.5s;
}

@keyframes sparkle {
    0%,
    100% {
        transform: scale(0) rotate(0deg);
        opacity: 0;
    }
    50% {
        transform: scale(1) rotate(180deg);
        opacity: 0.8;
    }
}

/* Touch device optimizations */
@media (hover: none) {
    .button:active {
        transform: scale(0.98);
    }

    .keyCard:active {
        transform: scale(0.98);
    }
}

/* Key management layout improvements */
.keysList {
    width: 100%;
    max-width: 280px;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin-bottom: 1.5rem;
    overflow-y: auto;
    padding: 0.5rem;
    max-height: calc(
        100vh - 400px
    ); /* Adjust based on your header/footer heights */
}

/* Smooth scrolling for keys list */
.keysList {
    scrollbar-width: thin;
    scrollbar-color: #93c5fd transparent;
}

.keysList::-webkit-scrollbar {
    width: 6px;
}

.keysList::-webkit-scrollbar-track {
    background: transparent;
}

.keysList::-webkit-scrollbar-thumb {
    background-color: #93c5fd;
    border-radius: 3px;
}

/* Fun hover effects for key cards */
.keyCard {
    position: relative;
    overflow: hidden;
}

.keyCard::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(90deg, transparent, #3b82f6, transparent);
    transform: translateX(-100%);
    opacity: 0;
}

.keyCard:hover::after {
    animation: slidingGlow 2s infinite;
    opacity: 1;
}

@keyframes slidingGlow {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

/* Enhanced button animations */
.button:not(:disabled):hover {
    transform: translateY(-2px) scale(1.02);
}

.button.addButton:not(:disabled):hover {
    background: linear-gradient(to bottom, #dbeafe, #bfdbfe);
}

.button.logoutButton:not(:disabled):hover {
    background: linear-gradient(to bottom, #fecaca, #fca5a5);
}

/* Floating animation for empty state */
.keysList:empty::after {
    content: 'No passkeys yet';
    display: block;
    text-align: center;
    color: #6b7280;
    font-family: 'GeistMono Regular';
    font-size: 0.9rem;
    padding: 2rem;
    animation: float 3s infinite ease-in-out;
}

@keyframes float {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

/* Loading state animations */
@keyframes shimmerLoading {
    0% {
        background-position: -200% center;
    }
    100% {
        background-position: 200% center;
    }
}

@keyframes sparkleFloat {
    0%,
    100% {
        transform: translate(0, 0) rotate(0deg);
        opacity: 0.2;
    }
    50% {
        transform: translate(3px, -3px) rotate(180deg);
        opacity: 0.8;
    }
}
/* Activity log loading state */
/* .logContainer.isLoading {
    position: relative;
    overflow: hidden;
} */

.logContainer.isLoading::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(90deg, transparent, #3b82f6, transparent);
    animation: shimmerLoading 2s linear infinite;
    background-size: 200% 100%;
}

.logContainer.isLoading::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(191, 219, 254, 0.1),
        transparent
    );
    animation: shimmerLoading 2s linear infinite;
    background-size: 200% 100%;
}

/* Log entry loading animation */
.logEntry {
    position: relative;
    overflow: hidden;
}

.logEntry.isNew {
    animation: newLogEntry 0.5s ease-out;
}

.logEntry.isNew::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(59, 130, 246, 0.1),
        transparent
    );
    animation: slideRight 1s ease-out;
}

@keyframes newLogEntry {
    0% {
        opacity: 0;
        transform: translateX(-10px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideRight {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}

/* Loading sparkles for the log container */
.loadingSparkles {
    position: absolute;
    inset: 0;
    pointer-events: none;
    overflow: hidden;
}

.loadingSparkle {
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #3b82f6;
}

.loadingSparkle:nth-child(1) {
    top: 20%;
    left: 10%;
    animation-delay: 0s;
}
.loadingSparkle:nth-child(2) {
    top: 60%;
    right: 10%;
    animation-delay: 0.3s;
}
.loadingSparkle:nth-child(3) {
    bottom: 20%;
    left: 15%;
    animation-delay: 0.6s;
}
.loadingSparkle:nth-child(4) {
    top: 30%;
    right: 15%;
    animation-delay: 0.9s;
}
.loadingSparkle:nth-child(5) {
    bottom: 40%;
    right: 20%;
    animation-delay: 1.2s;
}

/* Button loading state */
.button.isLoading {
    position: relative;
    background: linear-gradient(
        90deg,
        rgba(251, 252, 253, 0.95) 0%,
        rgba(243, 244, 246, 0.9) 25%,
        #bfdbfe 50%,
        rgba(243, 244, 246, 0.9) 75%,
        rgba(251, 252, 253, 0.95) 100%
    );
    background-size: 200% 100%;
    animation: shimmerLoading 2s linear infinite;
}

.button.isLoading .sparkle {
    animation: sparkleFloat 1.5s infinite;
    opacity: 1;
}

.button.isLoading .sparkleContainer {
    opacity: 1;
}

/* Profile Animation Styles */
.profileSection {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.profileImageWrapper {
    position: relative;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background: linear-gradient(to bottom, #eff6ff, #dbeafe);
    padding: 3px;
    box-shadow: 0 8px 16px rgba(59, 130, 246, 0.2);
}

.profileImage {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid white;
}

.profileSparkles {
    position: absolute;
    inset: -20px;
    pointer-events: none;
}

.welcomeText {
    font-family: 'Geist SemiBold';
    font-size: 1.25rem;
    color: #1f2937;
    text-align: center;
}

.welcomeText {
    font-family: 'Geist SemiBold';
    margin-top: -5px;
    font-size: 1.2rem;
    color: #1f2937;
    text-align: center;
}
