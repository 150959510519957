/* Font declarations with proper font-display */
@font-face {
    font-family: 'Geist Regular';
    src: url('fonts/gi/Geist-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geist Medium';
    src: url('fonts/gi/Geist-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geist Bold';
    src: url('fonts/gi/Geist-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geist Light';
    src: url('fonts/gi/Geist-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geist SemiBold';
    src: url('fonts/gi/Geist-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GeistMono Regular';
    src: url('fonts/gi/GeistMono-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GeistMono Medium';
    src: url('fonts/gi/GeistMono-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

body {
    margin: 0;
    font-family: 'Geist Regular';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* Add this at the top level of your CSS */
* {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}
